<template>
  <div class="table-component myprojects stock">
    <div class="table-component__header">
      <SelectComponent placeholder="Выберите отрасль" :options="industryList" class="mr-6" v-model="industryId" />
      <ButtonStock title="Выбрать" @click="loadItems" />
    </div>
    <div class="table-vidjet">
      <table>
        <thead>
          <colgroup width="30%"></colgroup>
          <colgroup width="20%"></colgroup>
          <colgroup width="20%"></colgroup>
          <colgroup width="20%"></colgroup>
          <tr>
            <th>Название</th>
            <th>Отрасль</th>
            <th>Инициатор</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody class="mh315">
          <tr v-for="item in items" :key="item.id" @dblclick="viewCard(item.id)">
            <td width="30%" :title="item.name">
              {{ item.name }}
            </td>
            <td width="20%" :title="item.industry.name">
              {{ item.industry.name }}
            </td>
            <td width="20%" :title="item.participantsDomesticStr">
              {{ item.participantsDomesticStr }}
            </td>
            <td width="10%" :title="item.status.name">
              <IconComponent v-if="item.statusId == 1" name="goodSigh" />
              <IconComponent v-else-if="item.statusId == 2" name="refreshSigh" />
              <IconComponent v-else name="grayGoodSigh" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-component__footer">
      <ButtonStock title="Все проекты" @click="goTo" />
    </div>
  </div>
</template>

<script>
  import SelectComponent from '@/common/components/selectComponent.vue';
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API_NSI from '@/modules/nsi/api/nsi';

  import API from '../api/widgetElement';

  export default {
    name: 'Widget7',
    components: {
      IconComponent,
      ButtonStock,
      SelectComponent,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (/*newValue*/) {
        this.loadItems();
      },
    },
    data() {
      return {
        items: [],
        industryList: [],
        industryId: null,
      };
    },
    created() {
      this.loadItems();
      API_NSI.search('NsiIndustry', { pageNumber: 1, pageSize: 0 }).then((response) => {
        if (response.data) {
          this.industryList = response.data.items.map((o) => {
            return { text: o.name, id: o.id };
          });
        }
      });
    },
    methods: {
      loadItems() {
        API.getMpkProject({
          countryIds: [this.countryId],
          industryIds: this.industryId > 0 ? [this.industryId] : null,
        }).then((response) => {
          this.items = response.data;
        });
      },
      goTo() {
        this.$router.push({ name: 'Projects' });
      },
      viewCard(id) {
        this.$router.push({ name: 'ProjectCard', params: { id: id } });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
